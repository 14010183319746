<template>
  <main>
    <header-black />
    <services-descr />
    <services-list />
  </main>
</template>

<script>
export default {
  name: 'ServicesPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    ServicesDescr: () => import('@/components/ServicesDescr'),
    ServicesList: () => import('@/components/ServicesList')
  }
}
</script>
